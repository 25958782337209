// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-concepts-tsx": () => import("./../../../src/pages/Concepts.tsx" /* webpackChunkName: "component---src-pages-concepts-tsx" */),
  "component---src-pages-digital-human-tsx": () => import("./../../../src/pages/DigitalHuman.tsx" /* webpackChunkName: "component---src-pages-digital-human-tsx" */),
  "component---src-pages-digital-story-telling-tsx": () => import("./../../../src/pages/DigitalStoryTelling.tsx" /* webpackChunkName: "component---src-pages-digital-story-telling-tsx" */),
  "component---src-pages-home-page-tsx": () => import("./../../../src/pages/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-tsx" */),
  "component---src-pages-swc-tsx": () => import("./../../../src/pages/Swc.tsx" /* webpackChunkName: "component---src-pages-swc-tsx" */)
}

