import { ThemeProvider } from "@emotion/react";
import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme } from "./styling/theme";

type ElementType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any;
};

export default ({ element }: ElementType): ReactNode => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </QueryClientProvider>
  );
};
