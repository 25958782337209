export const breakpoints = {
  xs: 330,
  mi: 520,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
};

export const MOBILE_VERSION_BREAKPOINT = breakpoints.mi;
export const TABLET_VERSION_BREAKPOINT = breakpoints.md;

export const media = {
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  maxXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  maxLg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  maxMd: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  maxSm: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
  maxMi: `@media screen and (max-width: ${breakpoints.mi - 1}px)`,
  maxXs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
};

export const color = {
  whiteColor: "#ffffff",
  textColor: "#222222",
  primaryColor: "#49e613",
  greyColor: "#7d7d7d",
  lightGreyColor: "#dcdcdc",
};

export type ColorT = {
  whiteColor: string;
  textColor: string;
  primaryColor: string;
  greyColor: string;
  lightGreyColor: string;
};

type MediaT = {
  xl: string;
  lg: string;
  md: string;
  sm: string;
  xs: string;
  maxXl: string;
  maxLg: string;
  maxMd: string;
  maxSm: string;
  maxMi: string;
  maxXs: string;
};

const fontSizeBase = 15;
type RemT = (val: number) => string;
export const rem: RemT = (val: number) => {
  return `${Math.round((val / fontSizeBase) * 10000) / 10000}rem`;
};

type ThemeInterface = {
  color: ColorT;
  media: MediaT;
  rem: (val: number) => string;
};

export const theme: ThemeInterface = {
  color,
  media,
  rem,
};

export const HEADER_HEIGHT = 72;
